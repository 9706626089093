<template>
  <div class="white-page">
    <router-link class="close-icon" to="/">
      <img
        src="@/assets/images/close-icon.svg"
        alt="close-icon"
        width="47"
        height="47">
    </router-link>
    <div class="paper-box">
      <div class="paper-menu">
        <div class="paper-menu-item">
          <div class="paper-menu-item_title">
            STR-ING.ORG
          </div>
          <div class="paper-menu-item_desc">
            FOUNDED and LED by Jonathan Touitou
          </div>
        </div>
        <div class="paper-menu-item">
          <div class="paper-menu-item_title">
            WEb DESIGN
          </div>
          <div class="paper-menu-item_desc">
            QUENTIN GAUDRY<br/>MAYA CUNAT<br/>TOBIAS WeNIG
          </div>
        </div>
        <div class="paper-menu-item">
          <div class="paper-menu-item_title">
            DEVELOPMENT
          </div>
          <div class="paper-menu-item_desc">
            Clemens Buchegger
            <br/>WhiteGeM
          </div>
        </div>
      </div>
      <div class="paper-content">
        <div class="paper-content_title">
          MANIFESTO
        </div>
        <div class="paper-content_desc">
          <p>
            Art is not only a retinal stimulation triggering [dis]pleasing emotions or thoughts. Dada is an excellent example of how painters, poets and musicians sparked new art techniques in the cultural field, such as collage, cut-up technique, photomontage, assemblage or readymades. Then labelled anti-art and today widely accepted, these techniques are used beyond the scope of aesthetics if only we think of the "cut and paste" command, whereby text or data is moved from one place in an electronic document or computer system to another. 
          </p>
          <p>
            Str-ing.org is an online platform that empowers content creators to publish and distribute their work through a content-sensitive decentralized database known as the Reservoir. Str-ing.org knowledge-sharing innovative technology is engineered to generate collective intelligence among artists, architects, performers, designers, writers, scientists, anthropologists, or mindfulness practitioners. 
          </p>
          <p>
            The platform's local-first approach to content creation ensures that visitors can access the most accurate insights and breakthroughs worldwide. By leveraging the power of its unique real-time editor, Str-ing.org enables these diverse practitioners to collect intelligence across disciplines and showcase in-depth content via str-ing.org Exhibition Viewer. Str-ing.org's decentralized protocol also ensures that these pieces of information won't either be corrupted or co-opted as they forever remain attached to the context in which their authors originally showcased them. 
          </p>
          <p>
            Focusing on innovative collaboration systems efficiently operating at scale, Str-ing.org is poised to become a leading solution in decentralized Collective Creativity and its emerging market.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhitePaper",
}
</script>

<style lang="scss" scoped>
.white-page {
  height: 100vh;
  padding: 100px 10vw;
  display: flex;
  flex-direction: column;
}

.paper-box {
  display: flex;
  .paper-menu {
    padding-top: 120px;
    .paper-menu-item {
      display: flex;
      margin-bottom: 50px;
      text-transform: uppercase;
      .paper-menu-item_title {
        width: 110px;
        color: #FF0000;
      }
      .paper-menu-item_desc {
        width: 175px;
        margin-left: 55px;
      }
    }
  }
  .paper-content {
    margin-left: 135px;
    .paper-content_title {
      font-size: 60px;
      margin-bottom: 40px;
    }
    .paper-content_desc {
      max-width: 762px;
      p {
        margin-bottom: 10px;
      }
    }
  }
}
.close-icon {
  display: block;
  position: absolute;
  top: 33px;
  right: 36px;
}

@media screen and (max-width: 1200px) {
  .white-page {
    padding: 10px 8px;
  }

  .paper-box {
    flex-direction: column;
    .paper-menu {
      padding-top: 60px;
    }
    .paper-content {
      margin-left: 0;
      .paper-content_title {
        font-size: 30px;
      }
    }
  }
  .close-icon {
    top: 15px;
    right: 15px;
    img {
      width: 30px;
      height: 30px;
    }
  } 
}
</style>
